const IconsMixin = {
    methods: {
        getIcon: (difference) => {
            if (difference >= -0.1 && difference <= 0.1) {
                return "el-icon-success color-success";
            } else if (difference >= -0.2 && difference <= 0.2) {
                return "el-icon-warning color-warning";
            } else if (difference >= -0.4 && difference <= 0.4) {
                return "el-icon-error color-danger";
            } else {
                return "el-icon-error";
            }
        },
        getActiveIcon: (active) => {
            if (active) {
                return "el-icon-success color-success";
            } else {
                return "el-icon-error color-danger";
            }
        },
    }
}

export default IconsMixin;
