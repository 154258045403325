<template>
  <div>
    <el-row justify="space-between">
      <el-col :span="4">
        <el-button type="primary" class="w-100" icon="el-icon-plus" @click="$refs.createUserDialog.show()">Crea Utente</el-button>
      </el-col>
      <el-col :span="4">
        <el-switch
            v-model="query.active"
            active-text="Visualizza solo attivi"
            inactive-text=""
            @change="loadList()"
        >
        </el-switch>
      </el-col>
      <el-col :span="8">
        <el-input placeholder="Cerca" v-model="query.s" @input="inputSearch" @clear="clearInput" clearable></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="userList">
        <el-table
            :data="userList"
        >
          <el-table-column prop="id" label="ID" width="50"></el-table-column>
          <el-table-column prop="userCode" label="Codice Univoco"></el-table-column>
          <el-table-column prop="fullname" label="Nominativo">
            <template #default="scope">
              {{ getFullName(scope.row)}}
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Email" ></el-table-column>
          <el-table-column prop="type" label="Tipo" >
            <template #default="scope">
              {{ getTypeLabel(scope.row.type) }}
            </template>
          </el-table-column>

          <el-table-column prop="address" label="Condominio" >
            <template #default="scope">
              {{ scope.row.apartments.length >0 ? scope.row.apartments[0].condominium.description : ""}}
            </template>
          </el-table-column>

          <el-table-column label="Ruolo" width="100">
            <template #default="scope">
              <span>{{ getRoleInitials(getMainRole(scope.row.roles)) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Stato" width="70">
            <template #default="scope">
              <i :class="getActiveIcon(scope.row.active)"></i>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template #default="scope">
              <el-tooltip class="item" effect="dark" content="Clicca per visualizzare le info dell'utente">
                <el-button @click="userSelected(scope.row)" type="primary" circle size="small" icon="el-icon-d-arrow-right"> </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-pagination v-if="userList"
          background
          layout="prev, pager, next, sizes"
          :total="totalUsers"
          :page-size="query.limit"
          v-model:currentPage="currentPage"
          :page-sizes="pagination.pageSizes"
          @size-change="sizeChange"
          @current-change="currentChange"
      >
      </el-pagination>
    </el-row>
    <form-dialog :title="'Crea Utente'" :fields="newUserFields" @submit="submittedCreateUser"
                 ref="createUserDialog"></form-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FormDialog from "../../components/FormDialog";
import {getNewUserFields} from "../../static/formFields";
import UserMixin from "../../mixins/UserMixin";
import IconsMixin from "../../mixins/IconsMixin";

export default ({
  name: "Utenti",
  components: {FormDialog},
  mixins: [UserMixin, IconsMixin],
  data() {
    return {
      newUserFields: [],
      currentPage: 1,
      pagination: {
        start: 1,
        pageSizes: [2, 5, 10, 20, 50]
      },
      query: {
        limit: 20,
        offset: 0,
        s: "",
        active: true
      },
      //activeView: true
    }
  },
  computed: {
    ...mapGetters(["userList", "totalUsers"])
  },
  methods: {
    ...mapActions(["fetchAllUsers", "registerUser"]),
    async submittedCreateUser(data) {
      if(data.admin){
        data.type = "admin";
      }
      await this.registerUser({...data});
      this.$message.success("Utente registrato");
    },
    userSelected(e){
      this.$router.push("/dashboard/utente/"+ e.id);
    },
    async sizeChange(val) {
      this.query.limit = val;
      //this.query.offset = (this.currentPage - 1) * this.query.limit;
      this.query.offset=0;
      this.currentPage=1;
      await this.fetchAllUsers(this.query);
    },
    async currentChange(val) {
      this.currentPage = val;
      this.query.offset = (val - 1) * this.query.limit;
      await this.fetchAllUsers(this.query);
    },
    inputSearch(){
      this.fetchAllUsers(this.query);
    },
    clearInput(){
      this.fetchAllUsers(this.query);
    },
    async loadList(){
      //console.log(this.query);
      await this.fetchAllUsers(this.query);
    }
    /*prevClick(val){
      console.log(val);
    },
    nextClick(val){
      console.log(val);
    }*/

  },
  async created() {
    await this.fetchAllUsers(this.query);
    this.newUserFields = getNewUserFields();
    // console.log(this.userList);
  }
})
</script>

<style scoped>

.color-danger {
  color: #F56C6C
}

.color-success {
  color: #67C23A
}

.color-warning {
  color: #E6A23C
}

</style>
